import axios from 'axios';
import { getApiUrl } from '../utils/helpers';

const API_URL = getApiUrl();

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response?.status, error.response?.data);
    return Promise.reject(error);
  }
);

export const createAPIKey = async (name) => {
  const response = await api.post('/api/key', {name});
  return response.data;
};

export const deleteAPIKey = async (keyId) => {
  const response = await api.delete(`/api/key`, { data: { apiKeyId: keyId } });
  return response.data;
};

export const createDocument = async (formData) => {
  const response = await api.post('/api/document', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteDocument = async (documentId) => {
  const response = await api.delete('/api/document', { data: { documentId: documentId } });
  return response.data;
};

export const submitQuery = async (projectId, projectName, llm, query, searches) => {
  try{
    const response = await api.post('/api/private-query', { projectId, projectName, llm, query, searches });
    return response;
  }catch(err){
    return err.response;
  }
  
};

export const fetchDashboardData = async () => {
  const response = await api.get('/dashboard');
  return response.data;
};

export const fetchProjectData = async (projectId) => {
  const response = await api.post('/api/project', {'apiKeyId': projectId});
  return response.data[0];
}

export const fetchUserData = async () => {
  const response = await api.get('/dashboard/user');
  return response.data;
}

export const createCustomer = async (paymentId) => {
  const response = await api.post('/billing/create-customer', { paymentId });
  return { data: response.data, status: response.status };;
}

export const createSubscription = async (subscriptionId, immediate=false) => {  
  const response = await api.post('/billing/create-subscription', { subscriptionId, immediate });
  return { data: response.data, status: response.status };
}

export const fetchProjectList = async () => {  
  const response = await api.get('/dashboard/projects');
  return response.data;
}

export const subscriptionCancel = async () => {
  const response = await api.post('/billing/cancel-subscription');
  return response;
};
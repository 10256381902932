import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import { useAuth } from './hooks/useAuth';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Lazy load components

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const QueryPage = lazy(() => import('./pages/QueryPage'));
const ProjectPage = lazy(() => import('./pages/Project'));
const Upgrade = lazy(() => import('./pages/Upgrade'));
const BillingInfo = lazy(() => import('./pages/BillingInfo'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));

ReactGA.initialize('G-LE1QY4MX86');

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.send({hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header />
      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Suspense fallback={
          <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
          </div>
        }>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/project/:projectId" element={<ProtectedRoute><ProjectPage /></ProtectedRoute>} />
            <Route path="/payment-info" element={<ProtectedRoute><BillingInfo /></ProtectedRoute>} />
            <Route path="/query" element={<ProtectedRoute><QueryPage /></ProtectedRoute>} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
          </Routes>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default App;
